/* @font-face { */
  /* font-family: primaryFont; */
  /* src: url('../public/assets/fonts/unifont_jp-15.0.06.otf') format("opentype"); */ 
/* } */
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@500&display=swap');
body {
  margin: 0px;
  letter-spacing: 2px;
  font-family: 'Zen Maru Gothic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  body div {
    font-weight: 700;
  }
  /* #mouseview{
    display: none;
  } */
}